import React from 'react';
import { graphql } from 'gatsby';
import { Box, Container, List, ListItem, VStack } from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { ServiceLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import Html from 'timelesstime-ui/components/html';
import CallMeBack from 'timelesstime-ui/components/call-me-back';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import Heading from 'timelesstime-ui/components/heading';
import PageLayout from '../components/layout';
import ServiceCard from '../components/service-card';

const ServicesPage = ({
  data: {
    primaryServices: { nodes: primaryServices },
    servicesPage,
    secondaryServices: { nodes: secondaryServices },
  },
}) => (
  <PageLayout
    leadInHeader={
      <ServiceLeadInHeader
        heading={servicesPage.heading || servicesPage.title}
        crumbs={[
          {
            url: '/services/',
            title: servicesPage.title,
          },
        ]}
      />
    }
  >
    <SEO
      title={servicesPage.title}
      keywords={servicesPage.fields.keywords}
      description={servicesPage.metaDescription}
      canonicalPath="/services/"
      thumbnail={servicesPage.jsonLdThumbnailImage}
    />

    <Container as="section" mb={4} maxW="container.lg">
      <Html source={servicesPage.fields.contentHtml} headerLevelStart={1} />
    </Container>

    <Container maxW="container.lg" mt={2} mb={4}>
      <VStack spacing={16}>
        {primaryServices.map((service, index) => (
          <Box as="section" key={service.id}>
            <ServiceCard service={service} imageOnRight={index % 2 === 0} />
          </Box>
        ))}
      </VStack>
    </Container>

    <Container maxW="container.lg" as="section" pt={8} pb={2} mt={5}>
      <Heading as="h2">
        Something else we can help with? Let&apos;s talk!
      </Heading>
      <List
        mb={6}
        display="grid"
        gridTemplateColumns={{
          base: 'auto',
          md: 'auto auto',
          lg: 'auto auto auto',
        }}
      >
        {secondaryServices.map((secondaryService) => (
          <ListItem key={secondaryService.id} my={1}>
            <CanonicalLink to={secondaryService.fields.path}>
              {secondaryService.title}
            </CanonicalLink>
          </ListItem>
        ))}
      </List>

      <Box textAlign="center" my={12}>
        <CallMeBack size="lg" callBackText="Specific question? Get in touch" />
      </Box>
    </Container>

    <Jumbotron as="aside" py={8} bg="tt.darkBlue">
      <NewsletterSignup />
    </Jumbotron>
  </PageLayout>
);

export const query = graphql`
  query ServicesPageQuery {
    servicesPage: contentfulPage(slug: { eq: "services" }) {
      title
      slug
      heading
      metaDescription
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        contentHtml
      }
    }
    primaryServices: allContentfulService(
      sort: { fields: [ordering], order: DESC }
      filter: { ordering: { ne: -1 }, isPrimary: { eq: true } }
    ) {
      nodes {
        id
        title
        slug
        title
        serviceTypeId
        serviceType
        createdAt
        updatedAt
        metaDescription
        featuredImage {
          ...FeaturedImage
        }
        jsonLdFeaturedImage: featuredImage {
          ...JsonLdFeaturedImage
        }
        caseStudies: case_study {
          id
          title
          slug
        }
        fields {
          path
          url
          writtenAt
          isNew
          keywords
          excerptHtml
        }
      }
    }
    secondaryServices: allContentfulService(
      sort: { fields: [title], order: ASC }
      filter: {
        ordering: { ne: -1 }
        isPrimary: { eq: false }
        title: { ne: null }
      }
    ) {
      nodes {
        id
        title
        fields {
          path
        }
      }
    }
  }
`;

export default ServicesPage;

import React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { getSrc, getImage } from 'gatsby-plugin-image';
import httpsUrl from 'timelesstime-ui/utils/https-url';
import JsonLd, { organizationId } from 'timelesstime-ui/components/json-ld';

import { id as serviceId } from './service'; /* eslint-disable-line import/no-cycle */

export const type = () => 'Article';
export const id = (slug) =>
  `https://timelesstime.co.uk/case-studies/${slug}#${type()}/`;

const orgId = organizationId();

const CaseStudyStructuredData = ({
  slug,
  title,
  description,
  keywords,
  plaintextContent,
  serviceSlug,
  keyQuoteText,
  createdAt,
  updatedAt,
  thumbnailImage,
  featuredImage,
  shortMeta,
}) => {
  const thumbnailImageSrc = getSrc(getImage(thumbnailImage));
  const featuredImageSrc = getSrc(getImage(featuredImage));
  return (
    <JsonLd
      data={{
        '@context': 'http://schema.org',
        '@type': type(),
        '@id': id(slug),
        about: {
          '@id': serviceId(serviceSlug),
        },
        author: {
          '@id': orgId,
        },
        creator: {
          '@id': orgId,
        },
        provider: {
          '@id': orgId,
        },
        publisher: {
          '@id': orgId,
        },
        producer: {
          '@id': orgId,
        },
        copyrightHolder: {
          '@id': orgId,
        },
        mainEntityOfPage: `https://timelesstime.co.uk/case-studies/${slug}/`,
        url: `https://timelesstime.co.uk/case-studies/${slug}/`,
        headline: title,
        name: title,
        description,
        keywords: keywords.join(','),
        comment: {
          text: keyQuoteText,
        },
        audience: {
          '@type': 'BusinessAudience',
          numberOfEmployees: {
            '@type': 'QuantitativeValue',
            minValue: '1',
            maxValue: '250',
          },
        },
        copyrightYear: format(createdAt, 'yyyy'),
        datePublished: format(createdAt, 'yyyy-MM-dd'),
        dateCreated: format(createdAt, 'yyyy-MM-dd'),
        dateModified: format(updatedAt, 'yyyy-MM-dd'),
        thumbnailUrl: httpsUrl(thumbnailImageSrc),
        image: httpsUrl(featuredImageSrc),
        ...(shortMeta && {
          text: plaintextContent,
        }),
      }}
    />
  );
};

CaseStudyStructuredData.defaultProps = {
  shortMeta: false,
  plaintextContent: '',
  createdAt: PropTypes.instanceOf(Date),
  updatedAt: PropTypes.instanceOf(Date),
};
CaseStudyStructuredData.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  plaintextContent: PropTypes.string,
  serviceSlug: PropTypes.string.isRequired,
  keyQuoteText: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date),
  updatedAt: PropTypes.instanceOf(Date),
  thumbnailImage: PropTypes.object.isRequired,
  featuredImage: PropTypes.object.isRequired,
  shortMeta: PropTypes.bool,
};

export default CaseStudyStructuredData;

import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Text, Flex, ButtonGroup } from '@chakra-ui/react';
import ContentCard from 'timelesstime-ui/components/content-card';
import ButtonLink from 'timelesstime-ui/components/button-link';
import ServiceStructuredData from '../structured-data/service';

const ServiceCard = ({ service, ...props }) => (
  <ContentCard
    url={service.fields.path}
    title={service.title.replace('...', '…')}
    image={
      <GatsbyImage
        image={getImage(service.featuredImage)}
        alt={service.featuredImage.title}
      />
    }
    structuredData={
      <ServiceStructuredData
        slug={service.slug}
        title={service.title}
        serviceType={service.serviceType}
        description={service.metaDescription}
        caseStudies={service.caseStudies || []}
        featuredImage={service.jsonLdFeaturedImage}
        shortMeta
      />
    }
    excerpt={service.fields.excerptHtml.replace('...', '…')}
    moreText={
      <Flex
        display="flex"
        flexDir={['column', 'row', 'row']}
        mt={6}
        width="100%"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <ButtonGroup
          colorScheme="orange"
          textAlign="center"
          width="100%"
          justifyContent="center"
        >
          <ButtonLink variant="solid" to={service.fields.path} my={[2, 0]}>
            <Text as="span" display={['none', 'block']}>
              More about {service.title.replace(/(.*?)[. …]*$/, '$1')}
            </Text>
            <Text as="span" display={['block', 'none']}>
              Read more
            </Text>
          </ButtonLink>
          <ButtonLink
            flexBasis="225px"
            to={`/case-studies?service=${encodeURIComponent(
              `["${service.serviceTypeId}"]`,
            )}`}
            my={[2, 0]}
            variant="outline"
          >
            view relevant case studies
          </ButtonLink>
        </ButtonGroup>
      </Flex>
    }
    {...props}
  />
);

export default ServiceCard;

import React, { forwardRef } from 'react';
import { Button } from '@chakra-ui/react';
import CanonicalLink from '../canonical-link';

const ButtonLink = forwardRef(({ to, ...props }, ref) => (
  <Button
    ref={ref}
    as={CanonicalLink}
    to={to}
    textDecoration="none"
    textTransform="lowercase"
    whiteSpace="normal"
    height="auto"
    px={2}
    py={2}
    sx={{
      // do these via sx to avoid nuking the defaut Button :hover styles
      '&:hover': {
        textDecoration: 'none',
      },
    }}
    {...props}
  />
));

export default ButtonLink;

import React from 'react';
import { titleCase } from 'title-case';
import { Box, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import CanonicalLink from '../canonical-link';
import Heading from '../heading';
import Html from '../html';

const ImageWrapper = ({ ...imageProps }) => (
  <Box
    minW={['200px', '200px', '350px', '400px']}
    maxW={['100%', '400px', '400px', '500px']}
    minH={['300px', '200px', '300px']}
    position="relative"
    {...imageProps}
  />
);

const ContentCardHeading = ({ to, children, ...props }) => (
  <Heading as="h2" {...props}>
    <CanonicalLink to={to}>{children}</CanonicalLink>
  </Heading>
);

const ContentCard = ({
  title,
  url,
  image,
  imageOnRight,
  structuredData,
  excerpt,
  moreText,
  children,
  imageMargin,
  wrapperProps,
  ...props
}) => {
  const imageOnLeft = !imageOnRight;
  return (
    <Box as="article" {...props}>
      {structuredData}
      {typeof title === 'string' && (
        <ContentCardHeading to={url}>{title}</ContentCardHeading>
      )}
      {typeof title === 'object' && title}
      <Flex
        flexDir={
          imageOnRight
            ? ['column', 'column', 'row']
            : ['column', 'column', 'row-reverse']
        }
        alignItems={['center', 'center', 'flex-start']}
        {...wrapperProps}
      >
        <Flex mt={2} flexGrow="1" flexDir="column" alignItems="center">
          {excerpt && <Html source={excerpt} headerLevelStart={1} />}
          {children}
          {moreText && typeof moreText === 'string' && (
            <Box mt={4}>
              <CanonicalLink color="gray.800" to={url}>
                {titleCase(moreText)}
              </CanonicalLink>
            </Box>
          )}
          {moreText && typeof moreText === 'object' && <>{moreText}</>}
        </Flex>
        {image && (
          <ImageWrapper
            ml={imageOnRight ? [0, 0, imageMargin] : 0}
            mr={imageOnLeft ? [0, 0, imageMargin] : 0}
            mt={[4, 4, 0]}
          >
            {image}
          </ImageWrapper>
        )}
      </Flex>
    </Box>
  );
};
ContentCard.defaultProps = {
  imageOnRight: true,
  excerpt: '',
  children: null,
  imageMargin: 4,
  moreText: null,
  image: null,
};
ContentCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.node,
  imageOnRight: PropTypes.bool,
  structuredData: PropTypes.node.isRequired,
  moreText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  excerpt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  imageMargin: PropTypes.number,
};

ContentCard.Heading = ContentCardHeading;

export default ContentCard;

import React from 'react';
import PropTypes from 'prop-types';
import { getSrc, getImage } from 'gatsby-plugin-image';

import httpsUrl from 'timelesstime-ui//utils/https-url';
import JsonLd, { organizationId } from 'timelesstime-ui/components/json-ld';

import { id as caseStudyId } from './case-study'; /* eslint-disable-line import/no-cycle */

export const type = () => 'Service';
export const id = (slug) =>
  `https://timelesstime.co.uk/services/${slug}#${type}/`;

const ServiceStructuredData = ({
  slug,
  serviceType,
  title,
  description,
  caseStudies,
  featuredImage,
}) => {
  const featuredImageSrc = getSrc(getImage(featuredImage));
  return (
    <JsonLd
      data={{
        '@context': 'http://schema.org',
        '@type': type(),
        '@id': id(slug),
        serviceType,
        name: title,
        description,
        url: `https://timelesstime.co.uk/services/${slug}/`,
        areaServed: {
          '@type': 'GeoShape',
          addressCountry: 'GBR',
        },
        provider: {
          '@id': organizationId(),
        },
        audience: {
          '@type': 'BusinessAudience',
          numberOfEmployees: {
            '@type': 'QuantitativeValue',
            minValue: '1',
            maxValue: '250',
          },
        },
        image: httpsUrl(featuredImageSrc),
        hoursAvailable: {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          opens: '00:00:00',
          closes: '23:59:59',
        },
        subjectOf:
          caseStudies && caseStudies.length > 0
            ? caseStudies.map((caseStudy) => ({
                '@id': caseStudyId(caseStudy.slug),
              }))
            : [],
      }}
    />
  );
};

ServiceStructuredData.defaultProps = {};

ServiceStructuredData.propTypes = {
  slug: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  caseStudies: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  ).isRequired,
  featuredImage: PropTypes.object.isRequired,
};

export default ServiceStructuredData;
